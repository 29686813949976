/*jshint esversion: 6 */
import global from './global.js';


export default function t(input){
  var self=this;

  var i18n=dictionary[global.language];

  var key='';
  try{
    key=input.toLowerCase().trim();
  }
  catch(e){
    key=input;
  }


  if(!i18n){
    return input;
  }
  else if (i18n=={}){
    return input;
  }
  else{
    if(i18n[key]){
      return i18n[key];
    }
    else{
      if(global.language!=='en'){
        console.log('||'+key+'|| not found');
      }
      return input;
    }
  }
}

var dictionary={
  "it": {
    "ciao":"Ciao",
    "accedi":"Accedi",
    "show_password":"Mostra password",
    "welcome_tec": "Benvenuto tecnico",
    "farm": "Azienda",
    "action": "Azione",
    "actions": "Azioni",
    "welcome": "Benvenuto",
    "access_anagrafica":"Accedi all'anagrafica",
    "anagrafe":"Anagrafe",
    "su":"su",
    "mesi":"mesi",
    "parti":"parti",
    "search_in_table":"Cerca in tabella..",
    "advanced_registry":"Anagrafica avanzata",
    "chose_language": "Scegli la tua lingua",
    "synchronize_data": "Sincronizza i dati",
    "back_home_page": "Torna alla Home Page",
    "impostazioni": "Impostazioni",
    "versione":"Versione",
    "rams_choice":"Scelta degli arieti",
    "ram_message":"Da questa funzione è possibile vedere la compatibilità del proprio gregge con gli arieti degli altri utenti e contattarne i proprietari.",
    "go_to_choice":"Vai alla scelta",
    "back":"Indietro",

    // OLIVE pheno aeco
    'winter dormancy':'gemme invernali',
    'start bud break':'gemme gonfie',
    // 'bud break':'gemme aperte',
    'leaf development':'sviluppo fogliare',
    'shoot development':'accrescimento germoglio',
    'inflorescence buds are closed':'comparsa grappoli fiorali',
    'inflorescence buds start to swell':'rigonfiamento bottoni fiorali',
    'corolla differentiation':'differenziazione corolla',
    'beginning of flowering':'inizio fioritura',
    'full flowering':'fioritura 50%',
    'fruit set':'allegagione',
    'fruit growth after fruit set':'accresc. frutto post allegagione',
    'stone hardening 0-50%':'indurimento del nocciolo (0-50%)',
    'stone hardening >50%':'indurimento del nocciolo (>50%)',
    'stone hardening 100%':'nocciolo indurito',
    'fruit growth green olives':'accrescimento frutto olive verdi',
    'fruit growth yellow-green olives':'accrescimento frutto olive giallo verdi',
    'veraison 5%':'Inizio Invaiatura 5%',
    'veraison 50%':'invaiatura 50%',
    'veraison 100%':'invaiatura 100%',

    // Grape pheno aeco
    'bud break':'germogliamento',
    'beginning leaf unfolded':'prime foglie distese',
    '5 leaves unfolded':'tralci lunghi 10 cm',
    'inflorescence visible':'grappoli visibili',
    'inflorescence swelling':'grappoli distesi',
    'flowers separating':'bottoni fiorali separati',
    'beginning of flowering (10%)':'inizio fioritura (10%)',
    'full flowering (50%)':'piena fioritura (50%)',
    'fruit set (10%)':'inizio allegagione 10%',
    'fruit set (50%)':'allegagione 50%',
    'fruit set (100%)':'allegagione completa',
    'berries groat-sized':'acino grano di pepe',
    'berries pea-sized':'acino pisello',
    'berries beginning to touch':'prechiusura grappolo',
    'berries touching':'grappolo chiuso',
    'beginning of ripening':'inizio invaiatura',
    'berries developing colour':'invaiatura 50%',
    'softening of berries':'invaiatura completa',
    'maturation':'maturazione',
    'agronomist':'Agronomo/Consulente',

  },
  "en": {
    'monitoraggio':'Monitoring',
    'operazioni colturali':'Crop operations',
    'ordinamento colturale':'Cropping system',
    'stagione':'season',
    'modello':'Model',
    'fase prevalente':'Prevalent phase',
    // 'gradi giorno': 'day degrees',
    'elimina il campo':'delete the field',
    'gradi giorno cumulati':'Day degrees cumulated',
    'fenologia dell\'olivo':'Olive phenology',
    "ciao":"Hi",
    "accedi":"Log in",
    "show_password":"Show password",
    "welcome_tec": "Welcome technician",
    "farm": "Farm",
    "action": "Action",
    "action": "Actions",
    "welcome": "Welcome",
    "access_anagrafica":"Access the registry",
    "anagrafe":"Registry",
    "su":"over",
    "mesi":"months",
    "parti":"deliveries",
    "search_in_table":"Search in table..",
    "advanced_registry":"Advanced registry",
    "chose_language": "Choose your language",
    "synchronize_data": "Synchronize data",
    "back_home_page": "Back to Home Page",
    "impostazioni": "Settings",
    "versione":"Version",
    "rams_choice":"Choice of rams",
    "ram_message":"From this function is possible to see the compatibility of your flock with the rams of other users and contact its owners.",
    "go_to_choice":"Go to the choice",
    "back":"Back",
    "esci":"Log out"

  }
};
