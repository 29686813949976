/*jshint esversion: 6 */

import global from "../global.js";
import storage from "../storage.js";
import * as tools from "../tools.js";
import t from '../translate.js';
import * as p_data from './partecipa/data.js';

import writeImage from './write_image.js';

class photo {

  getPhotoDiv(guid_rilievo){
    var html='';
    var fileda4=1;

    var s=storage.get();
    var num_photo=0;

    try {
      if (typeof s.my_images=='undefined') {
        s.my_images={};
      }
      if (typeof s.my_images[guid_rilievo]=='undefined') {
        s.my_images[guid_rilievo]={};
      }

      num_photo=Object.keys(s.my_images[guid_rilievo]).length;
    }
    catch (e) {
      console.log(e);
    }

    fileda4=parseInt(parseInt(num_photo/4)+1);
    console.log(fileda4);
    for (var i = 0; i < fileda4; i++) {
      html+='<div class="row" style="margin-bottom:10px;">';

        html+='<div class="photo" id="photo_'+(1+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(2+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(3+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(4+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

      html+='</div>';
    }

    return html;
  }

  fillPhotoDiv(guid_rilievo){
    var self=this;
    // debugger
    var s=storage.get();
    var cont=0;
    var cont_getbase64=1;

    if (typeof s.my_images=='undefined') {
      s.my_images={};
    }
    if (typeof s.my_images[guid_rilievo]=='undefined') {
      s.my_images[guid_rilievo]={};
    }

    // global.app.dialog.preloader('Sto processando le foto.');
    // debugger
    var pair={};

    jQuery.each(s.my_images[guid_rilievo],function(k,v){
      v.guid=k;
      console.log(v);
      cont++;

      pair[v.guid]=cont;

      // var time=500;
      // setTimeout( function(){

        // console.log("IMAGE----");
        console.log("imageUrl",v.imageUrl);
        var path=v.imageUrl;
        if (path=="") {
          // debugger
          if (global.iOS() && !v.base64.startsWith('data:image')) {
            v.base64=`data:image/png;base64,${v.base64}`
          }
          path=v.base64;
          var html='';
          html+='<div>';
            html+=`<img width="60" height="80" src="${self.isImage(v.base64)?v.base64:'p_icons/file.png'}"></img>`;
          html+='</div>';
          console.log("HTML image:",html);
          jQuery('#photo_'+(cont)).html(html);

        }
        else {
          if (v.imageUrl.indexOf('filesystem')==0) {
            // debugger
            p_data.getFileContentAsBase64(v.imageUrl,function(base64Image){
              console.log("xxxxxxxxxxxxxxx");
              var base64=base64Image;

              if (!self.isImage(base64)) {
                base64='p_icons/file.png';
              }

              console.log(cont,v.imageUrl);
              var html='';
              html+='<div>';
                html+='<img width="60" height="80" src="'+base64+'"></img>';
              html+='</div>';
              console.log(v.guid, pair[v.guid]);
              jQuery('#photo_'+(pair[v.guid])).html(html);
              // cont_getbase64++;
            });
          }
          else {
            var html='';
            html+='<div>';
              html+='<img width="60" height="80" src="'+path+'"></img>';
            html+='</div>';
            jQuery('#photo_'+(cont)).html(html);
          }

        }


      // }, time);
      // time += 500;
    });
    // global.app.dialog.close();

    var photos=[];
    jQuery.each(s.my_images[guid_rilievo],function(k,v){
      // if (!global.is_cordova()) {
      //   photos.push(v.base64);
      // }
      // else {
      // }
      if (v.imageUrl!='') {
        if (v.imageUrl.indexOf('filesystem')==0) {
          p_data.getFileContentAsBase64(v.imageUrl,function(base64Image){
            var base64=base64Image;
            photos.push(base64);
          });
        }
        else {

        }
      }
      else {
        photos.push(v.base64);
      }

    });

    setTimeout(function(){
      // console.log(photos);
      var myPhotoBrowserStandalone = global.app.photoBrowser.create({
        photos: photos
      });
      jQuery(".photo").click(function() {
        if (photos.length>0) {
          myPhotoBrowserStandalone.open();
        }
      });
    },1500);


  }

  isImage(data){
    if( global.nomeApp=='Agro Abruzzo' || (data && data.startsWith('data:image'))){
      return true;
    }
    else{
      return false;
    }
  }

  takePicture(guid_rilievo){
    var self=this;
    if(global.is_cordova()){

      var cameraOptions={
        quality: 75,
        correctOrientation:true,
        destinationType: navigator.camera.DestinationType.FILE_URI,
        targetWidth:1280,
        targetHeight:1280
      };

      if (global.iOS()) {
        cameraOptions.destinationType=navigator.camera.DestinationType.DATA_URL;
      }
      navigator.camera.getPicture(
        function(imageUrl){

          console.log("klean image in iOS",imageUrl);
          var base64 = "";
          if (global.iOS()) {
            base64=imageUrl
            imageUrl='';
          }

          self.save_image(base64,imageUrl,guid_rilievo);
          console.log(imageUrl);
        },
        function(message){
          alert(message);
        },
      cameraOptions);
    }
    else{
      if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
        alert('The File APIs are not fully supported in this browser.');
      }
      var input = document.getElementById('scatta_foto');
      // console.log(input);
      if (!input) {
         alert("Um, couldn't find the file input element.");
      }
      else if (!input.files) {
        alert("This browser doesn't seem to support the `files` property of file inputs.");
      }
      else if (!input.files[0]) {
        alert("Please select a file");
      }
      else {
        console.log("FOTOFINA");



        var file = input.files[0];
        var fr = new FileReader();
        fr.onload = function(){
          var base64=fr.result;


          // filesystem:http://localhost:8080/persistent/IMG_20210401_113317.jpg
          // filesystem:http://localhost:8080/persistent/IMG_20210401_113317.jpg
          // debugger
          // console.log("klean iOS",iOS());
          if (global.iOS()) {
            var guid=tools.getGuid();
            var settings=storage.get();

            // alert('iPhone non supporta ancora le foto');
            global.app.dialog.preloader('Sto caricando le foto.');

            var urladdr = global.base_path+"monitoring_webpack/store_images/insert/"+guid;
            jQuery.ajax({
              type: 'POST',
              async:false,
              data:{
                guid_survey:guid_rilievo,
                note:'',
                base64:base64,
                access_token:settings.user.access_token,
              },
              url: urladdr,
              dataType: "json",
              success: function(preverbale){
                global.app.dialog.close();

                console.log(" img "+guid+" "+preverbale.ok+"<p/>");
                if (preverbale.ok) {

                  var link=global.base_link+'sites/default/files/images/'+guid+".png";
                  if (!self.isImage(base64)) {
                    link='p_icons/file.png';
                  }
                  self.save_image('',link,guid_rilievo);
                }
                // updateImageStatus(guid, preverbale.ok);
              },
              error:function(e){
                console.log(e);
                // debugger
                global.app.dialog.close();

                alert('Foto non caricata. Verificare di essere connesso alla rete.');
              }
            });

          }
          else {
            writeImage(file.name, file,1);
            self.save_image('','filesystem:'+window.location.origin+'/persistent/'+file.name,guid_rilievo);
          }

          // console.log('filesystem:http://localhost:8080/persistent/'+file.name);
          // console.log(fr);
        };
        fr.readAsDataURL(file);
     }
    }
  }

  save_image(base64,imageUrl,guid_rilievo){
    var self=this;
    console.log("Base64",base64);
    console.log("imageurl",imageUrl);


    var s=storage.get();
    var my_images=s.my_images;

    if (typeof s.my_images=='undefined') {
      s.my_images={};
    }
    if (typeof s.my_images[guid_rilievo]=='undefined') {
      s.my_images[guid_rilievo]={};
    }

    if (my_images==null) {
      my_images={};
    }

    if (my_images[guid_rilievo]==null) {
      my_images[guid_rilievo]={};
    }

    var guid=tools.getGuid();
    my_images[guid_rilievo][guid]={'base64':base64,'imageUrl':imageUrl,'saved':false};

    // debugger
    if (imageUrl!='' && imageUrl.indexOf('filesystem')=='-1') {
      my_images[guid_rilievo][guid].saved=true;
    }

    s.my_images=my_images;
    console.log(s);
    // debugger


    storage.save(s);


    setTimeout(function() {
      self.fillPhotoDiv(guid_rilievo);
    }, 200);

    // location.reload();
  }

}


export default (new photo());
