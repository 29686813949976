/*jshint esversion: 6 */
import global from "../../global.js";
import mapHelper from '../map.js';
import gps from '../gps.js';
import storage from "../../storage.js";

class view_data {
  constructor(opt) {
    console.log(global);
    console.log(opt);
    global.aedita_opt=opt;
    global.style=opt.style;
    var theme=new Dbmng.BootstrapTheme();
    if (opt.style=='f7') {
      theme=new Dbmng.Framework7Theme();
    }

    Dbmng.defaults.theme=theme;
  }

  visualizzazioneDati(year){
    var self=this;
    var s=storage.get();

    var map=new mapHelper();
    var request={};
    if (year) {
      request.year=year;
    }
    var urladdr = global.base_call_aedita+"mod_aedita_services/get_combined";
    jQuery.ajax({
      type: 'POST',
      url: urladdr,
      data:request,
      dataType: "json",
      success: function(data){

        var opt_survey_schemas='';
        jQuery.each(data.survey_schema,function(k,v){
          opt_survey_schemas+=`<option value="${v.id_survey_schema}">${v.name}</option>`;
        });

        var content=`

        <div class="rodw" id="sel_data">
          <div class="cdol-33 list" >
            <ul>
              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="id_survey_schema"> ${opt_survey_schemas} </select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Scheda</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="id_survey_var"></select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Variabile</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="week"></select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Data</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>

              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="year"></select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Anno</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>

            </ul>
          </div>
        </div>
        <div id="agroMap">${map.getHtml(false)}<div>
        <div class="map_legend"></div>
        `;



        var page={
          exclude_card:true,
          title: "Visualizzazione",
          content: content
        };

        global.theme.render(page);

        var opt_year='';
        for (var i = new Date().getFullYear(); i >= 2021; i--) {
          var selected='';
          if (year==i) {
            selected='selected';
          }
          opt_year+=`<option value="${i}" ${selected}>${i}</option>`;
        }
        jQuery('#year').html(opt_year);//.change();,
        jQuery('#year').parent().find('.item-after').text(jQuery("#year option:selected").text());


        jQuery('#year').change(function(){
          self.visualizzazioneDati(jQuery(this).val());
        });

        jQuery('#agroMap').parent().css('margin',0);
        jQuery('#agroMap #map_container').css('height',outerHeight/2.2);

        jQuery('#runGPS').parent().remove();

        jQuery("#manual-Map-checkbox input").change(function() {
          jQuery('#manual-Map-checkbox').css('opacity','0.5');
          jQuery(this).attr("disabled", true);
          gps.manualMap();

          jQuery('#manual_gps_latitude').change(function(){
            // self.getTiles({
            //   bufferedLatitude:parseFloat(jQuery('#manual_gps_latitude').val()),
            //   bufferedLongitude:parseFloat(jQuery('#manual_gps_longitude').val())
            // });
          });
        });



        var point={latitude:43.32517767999296,longitude:11.744384765625002};

        if (global.nomeRegione=='Abruzzo') {
          // Abruzzo
          point={latitude:42.5,longitude:14};
        }

        global.createMap('map_container',point,8);


        self.runGPS();


        jQuery("#sel_data select").on("change", function(){
          if (jQuery(this).attr('id')!='year') {
            var id_changed=jQuery(this).attr("id");
            var id_survey_schema=jQuery("#id_survey_schema").val();

            if(id_changed=='id_survey_schema'){
              var opt_survey_vars='';
              var opt_weeks='';

              jQuery.each(data.survey_schema,function(k,v){
                if (v.id_survey_schema==id_survey_schema) {
                  jQuery.each(v.survey_var,function(j,sv){
                    console.log(sv);
                    opt_survey_vars+=`<option value="${sv.var_name}">${sv.name}</option>`;
                  });

                  jQuery.each(v.week,function(i,w){
                    console.log(w);
                    opt_weeks+=`<option value="${w.week_number}">${w.week}</option>`;
                  });

                }
              });

              console.log(opt_survey_vars);
              console.log(opt_weeks);


              jQuery('#week').html(opt_weeks);//.change();
              jQuery('#week').parent().find('.item-after').text(jQuery("#week option:selected").text());

              jQuery('#id_survey_var').html(opt_survey_vars);//.change();
              jQuery('#id_survey_var').parent().find('.item-after').text(jQuery("#id_survey_var option:selected").text());

              //update id_survey_var e week
            }

            var id_survey_var=jQuery("#id_survey_var").val();
            var week=jQuery("#week").val();
            var year=jQuery("#year").val();

            self.viewData(id_survey_schema, id_survey_var, week, year);
          }
        });

        jQuery("#id_survey_schema").change();
        jQuery('#id_survey_schema').parent().find('.item-after').text(jQuery("#id_survey_schema option:selected").text());


      },
      'error': function(err){
        // alert('OPS, non è stato possibile caricare i dati..');
        global.app.dialog.close();
        var page={
          exclude_card:true,
          title: "OPS, non è stato possibile caricare i dati",
          content: ''
        };

        global.theme.render(page);

      }
    });

  }

  isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
  }
  //Vedi un singolo dato su mappa
  viewData(id_survey_schema, id_survey_var, week, year){
    // debugger
    console.log(year);

    var self=this;
    console.log(id_survey_schema,id_survey_var,week);

    // Ripulisco la mappa dai vecchi marker
    jQuery('.leaflet-ab-circle').remove();

    var urladdr = global.base_call_aedita+"mod_aedita_services/get_combined_data/"+id_survey_schema+"/"+week;
    jQuery.ajax({
      type: 'POST',
      url: urladdr,
      data:{
        year:year
      },
      dataType: "json",
      success: function(data){
        console.log(data);
        if (data.survey.data.length>0) {

          var styles=null;
          if (data.survey_schema.survey_var[0].json && data.survey_schema.survey_var[0].json!==null) {
            // debugger
            styles=JSON.parse(data.survey_schema.survey_var[0].json);
          }

          jQuery.each(data.survey.data,function(k,v){
            var color='blue';
            var realColor='';
            try {
              var cat=JSON.parse(v.val);
              console.log(data);

              var value_survey=null;
              if (isNaN(parseFloat(cat[jQuery('#id_survey_var').val()]))) {
                value_survey=(cat[jQuery('#id_survey_var').val()]);
              }
              else {
                value_survey=parseFloat(cat[jQuery('#id_survey_var').val()]);
              }

              color=self.getCellColor(styles,v, value_survey);
              realColor=color;

              var extension_class='';
              if (color=='#FFFFFF') {
                color='white';
              }
              else if(color=='#4CAF50') {
                color='green';
              }
              else if(color=='#FFEB3B') {
                color='yellow';
              }
              else if(color=='#F44336') {
                color='red';
              }
              else {
                extension_class='custom_color';
              }

            }
            catch (e) {
              console.log(v.val);
              console.log(e);
            }


            var myIcon = L.divIcon({
              iconSize: new L.Point(20, 20),
              className: 'leaflet-ab-circle data_from_'+v.tipo+' '+extension_class+' leaflet-div-icon-circle-'+color
            });


            console.log("--------------");
            console.log(v,v.val);
            console.log("--------------");

            L.marker([v.lat,v.lon], {icon: myIcon}).addTo(global.map).bindPopup(typeof(v.val)=='string'?v.val.replace(/[\{\}"]/g,''):v.val);

            jQuery('.custom_color').css('background-color',realColor);
          });





          var style=styles.style;
          var legend="";
          var color;
          var value_in_legend;

          jQuery.each(style, function(k,v){
            // debugger
            if (typeof v.value!='undefined' && v.value+""!=='NaN') {
              if (typeof v.value=='string') {
                value_in_legend=v.value;
                color=v.color;

                if (typeof v.label!='undefined') {
                  value_in_legend=v.label;
                }
              }
              else {
                value_in_legend=v.value.toFixed(2);
                color=v.color;

                //debugger
                if (typeof v.label!='undefined') {
                  value_in_legend=v.label;
                }
                else {
                  jQuery.each(data.survey_schema.survey_var,function(j,t) {
                    // debugger
                    // if (t.widget=='select') {
                      jQuery.each(data.survey_schema.survey_code,function(i,d) {
                        if (d.id_survey_code==value_in_legend) {
                          value_in_legend=d.name;
                        }
                      });
                    // }
                  });
                }
              }

              legend+='<li id="legend_value_'+v.value+'" style="'+style+'"><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="'+color+'"/></svg>'+value_in_legend+'</li>';
            }
          });


          if(legend===''){
            legend+="Non ci sono punti";
          }
          else {
            legend+=`<li id="legend_value_circle"><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="#2196f3"/></svg>Dati non validi</li>`;

          }

          jQuery('.map_legend').html('<ul>'+legend+'</ul>');



          // var legend=`
          //     <li id="legend_value_circle"><svg style="margin-right: 10px;" height="20" width="20"><rect width="20" height="20" stroke="black" fill="#2196f3"/></svg>Dati monitoraggio</li>
          //     <li id="legend_value_circle"><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="#2196f3"/></svg>Dati partecipativi</li>
          // `;
          //
          // jQuery('.map_legend').html('<ul>'+legend+'</ul>');
        }

        global.app.dialog.close();
      },
      'error': function(err){
        global.app.dialog.close();
      }
    });


  }

  //Produci il colore della cella in funzione degli stili styles e del v (field)
  getCellColor(styles, v, value){
    var self=this;
    // debugger
      var color='';
      if (styles!==null && !self.isEmpty(styles)) {

        // console.log(v.widget);
          if(typeof styles.style!=='undefined'){
            jQuery.each(styles.style,function(i,d){
              try{
                var valore=parseFloat(value);
                var minimo=d.value;
                var massimo=minimo;
                if((i+1)<(styles.style).length){
                  massimo=styles.style[i+1].value;
                }
                else {
                  if(valore>= minimo){
                    color=d.color;
                  }
                }
                if(valore>= minimo && valore<massimo){
                  color=d.color;
                }
              }
              catch(e){console.log(e);}
              if (d.value==value) {
                color=d.color;
                return false;
              }
            });
          }
      }
      console.log(color);
      return color;
  }

  runGPS(){
    var self=this;
    gps.startGPS({
      getFasterPosition:true,
      save_place: true, //dentro la libreria chiama il reverse geocode e lo salva in global.coordinates
      buffer_max_size: 22222222222,
      position: function(pos){
        // debugger
        if (global.blocked_pos!==true) {
          console.log("KLEAN",pos);
          jQuery('#localizationIcon').html('<i class="f7-icons">location_fill</i>');


          // if (global.is_cordova()) {
            //   pos=JSON.parse(pos);
            // }
            pos.timestamp=new Date().getTime();
            global.coordinates=pos;

            console.log(pos);

            setTimeout(function(){
              global.marker = L.marker([pos.bufferedLatitude,pos.bufferedLongitude]).addTo(global.map);
            },1000);
            console.log(pos);

            global.map.panTo([pos.bufferedLatitude,pos.bufferedLongitude],{animate:true});
            // self.getTiles(pos);
        }
        else {
          console.log("posizione bloccata...");
        }

      }
    });

  }

}

export default (view_data);
