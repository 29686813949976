/*jshint esversion: 6 */

import global from "./global.js";
import storage from "./storage.js";
import * as tools from "./tools.js";
import t from "./translate.js";

export function doLogin(){
  // jQuery('.left').html('');

  var html='';

  html+='<div class="page">';
    html+='<div class="page-content login-screen-content">';
      html+='<div class="login-screen-title">'+t('Login')+'</div>';

      if (global.useSocialLogin) {
        html+='<div class="list" style="margin-top: 120px; margin-bottom: 120px;">';
          html+='<ul>';
            // html+='<li class="item-link">';
            //   html+='<button id="facebookLogin" class="col button button-large "><i class="f7-icons">logo_facebook</i> Login With Facebook</button>';
            // html+='</li>';
            html+='<li style="margin:15px;" class="item-link">';
              html+='<button style="border-radius:25px;" id="googleLogin" class="col button button-large button-fill"><i class="f7-icons color-white" style="margin-top: 8px; float:left;">logo_google</i> <span style="font-size: 30px;">Accedi con Google</span></button>';
            html+='</li>';
          html+='</ul>';
        html+='</div>';


        html+=`
        <div style="text-align: center; ">
          <span style="color: #aaa;">oppure</span>
        </div>
        `;
      }


      html+='<form>';
        html+='<div class="list">';
          html+='<ul>';
            html+='<li class="item-content item-input">';
              html+='<div class="item-inner">';
                html+='<div class="item-title item-label">Username</div>';
                html+='<div class="item-input-wrap">';
                  html+='<input autocapitalize="off" autocorrect="off" type="text" name="username" id="username" placeholder="Your username">';
                html+='</div>';
              html+='</div>';
            html+='</li>';
            html+='<li class="item-content item-input">';
              html+='<div class="item-inner">';
                html+='<div class="item-title item-label">Password</div>';
                html+='<div class="item-input-wrap">';
                  html+='<input autocapitalize="off" autocorrect="off" type="password" name="password" id="user_password" placeholder="Your password">';
                html+='</div>';
              html+='</div>';
            html+='</li>';

            html+='<li class="item-content item-input">';
              html+='<p style="color:#a9a9a9"><label class="checkbox"><input id="showPassword" type="checkbox"><i class="icon-checkbox"></i></label><i> '+t('show_password')+'</i></p>';
            html+='</li>';


          html+='</ul>';
        html+='</div>';
        html+='<div class="list">';
          html+='<ul>';
            html+='<li><a class="item-link list-button" id="submitLogin">'+t('Accedi')+'</a></li>';
          html+='</ul>';
        html+='</div>';
      html+='</form>';


    html+='</div>';
  html+='</div>';

  jQuery('#f7_content').html(html);
  jQuery('#footer').html("");

  jQuery("#facebookLogin").click(function() {
    console.log("fb");
    loginWithFacebook();
  });
  jQuery("#googleLogin").click(function() {
    console.log("google");
    if (global.is_cordova()) {
      loginWithGoogle();
    }
    else {
      webGoogleLogin();
    }
  });


  jQuery("#showPassword").change(function() {
    var x = document.getElementById("user_password");
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
  });

  var s=storage.get();

  jQuery("#submitLogin").click(function() {

    try {
      global.app.dialog.preloader(global.loadingText);
    }
    catch (e) {
      alert("Errore Try CAtch");
    }


    var urladdr = global.base_call+"token";
    jQuery.ajax({
      type: "GET",
      url: urladdr,
      data:{
        grant_type:'password',
        username:jQuery('#username').val(),
        password:jQuery('#user_password').val(),
        client_id:'smb-portal'
      },
      dataType: "json",
      success: function(user){
        global.app.dialog.close();

        if (user!=null && user.ok) {
          console.log(user);
          loadUserjStorage(user);
        }
        else {
          global.app.dialog.alert("Username e/o password errati. Riprovare.", "Errore nell'accesso");
        }
      },
      error: function(e,status,error){
        global.app.dialog.close();
        global.app.dialog.alert("Si è verificato un errore nella chiamata. Questo errore non dipende da te.", "Errore nell'API");
        console.log(e);
      },
    });

  });
}

// -----------GOOGLE WEB LOGIN INIZIO ------------------

function webGoogleLogin(){

  console.log("WEB LOGIN");

  window.location.href=GetOAuthURL();

  // // Check if page is secure
  // if (IsSecure()) {
  //
  // }
}


// Function for building the oauth url for the authentication link
function GetOAuthURL() {

    // Set basic variables

    var redirect_uri=location.origin
    if (global.redirect_uri) {
      redirect_uri=global.redirect_uri;
    }
    var redirect_url = [redirect_uri].join('');
    var oAuthEndPoint = "https://accounts.google.com/o/oauth2/auth";
    var oAuthClientID = "53405368259-k585l8fv4iefejh6a36enm021ci9sigf.apps.googleusercontent.com";
    var oAuthScope = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";


    // URL Encode parameters
    var redirect_uri = encodeURIComponent(redirect_url); // Get current URL
    var client_id = encodeURIComponent(oAuthClientID);
    var scope = encodeURIComponent(oAuthScope);

    // Build the actuall url
    var oauth_url = oAuthEndPoint + "?client_id=" + client_id + "&redirect_uri=" + redirect_uri + "&scope=" + scope + "&response_type=token";

    console.log("oAuth URL: "+oauth_url);
    return oauth_url;
}

export function LoadUserInfo(access_token, expires_in) {
    jQuery.ajax({
        url: 'https://www.googleapis.com/userinfo/v2/me',
        type: 'GET',
        dataType: 'json',
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        success: function (data) {

          var google_data={
            accessToken:access_token,
            userId:data.id,
            displayName:data.given_name+' '+data.family_name,
            email: data.email
          };

          socialLogin('google',google_data);

            // // Populate demo, img and name
            // jQuery("#user_pic").attr("src", data.picture);
            // jQuery("#user_name").attr("href", data.link);
            // jQuery("#user_name").text(data.name);
            //
            // // Show raw data
            // for (var property in data) {
            //     if (data.hasOwnProperty(property)) {
            //         jQuery("#raw_data").append("<b>" + property + ":</b>" + data[property] + "<br/>");
            //     }
            // }
            //
            //

        },
        error: function () {
            jQuery('#demo').html('<p>An error has occurred</p>');
        }
    });
}

// Function for extracting URL parameters returned to the page after oauth

// -----------GOOGLE WEB LOGIN FINE ------------------

function loadUserjStorage(user){
  var s=storage.get();
  console.log("---------------");
  console.log(user);
  console.log("---------------");
  s.user={
    access_token:user.access_token,
    uid:user.user.uid,
    username:user.user.name.split('-')[0],
    roles:user.user.roles,

  };
  if (user.user.init) {
    s.user.email=user.user.init;
  }
  else {
    s.user.email=user.user.mail;
  }
  storage.save(s);

  console.log(user);

  tools.register();

  location.hash='tools/sync';


  setTimeout(function(){
    location.reload(false);
  }, 100);
}

function loginWithFacebook(){
  if (typeof facebookConnectPlugin!='undefined') {
    facebookConnectPlugin.login(["public_profile","email"],function(result){
      console.log(JSON.stringify(result));

      facebookConnectPlugin.api("/me?fields=email,name,picture",["public_profile","email"], function(userData){
        // alert(JSON.stringify(userData));
        socialLogin('facebook',result,userData);
      },function(error){
        alert(JSON.stringify(error));
      });


    },function(error){
      alert(JSON.stringify(error));
    });
  }
  else {
    alert("Plugin facebook login assente");
  }
}

function loginWithGoogle(){
  if (typeof window.plugins!='undefined' && typeof window.plugins.googleplus!='undefined') {
//
    window.plugins.googleplus.login({

    },function(result){
      socialLogin('google',result);
      // alert(JSON.stringify(result));
    },function(error){
      alert(JSON.stringify(error));
    });
  }
  else {
    alert("Plugin google login assente");
  }
}


function socialLogin(provider,result,userData){

  var token=null;
  var name=null;
  var user_id=null;
  var email=null;

  if (provider=='facebook') {
    token=result.authResponse.accessToken;
    user_id=result.authResponse.userID;
    name=userData.name;
    email=userData.email;
  }
  else if (provider=='google') {
    token=result.accessToken;
    user_id=result.userId;
    name=result.displayName;
    email=result.email;
  }

  global.app.dialog.preloader(global.loadingText);
  var urladdr = global.base_call+"social_login";
  // alert(urladdr);
  jQuery.ajax({
    type: "GET",
    url: urladdr,
    data:{
      social_provider:provider,
      social_token:token,
      social_uid:user_id,
      social_name:name,
    },
    dataType: "json",
    success: function(data){
      global.app.dialog.close();

      // alert(JSON.stringify(data));
      //
      // console.log("---");
      // console.log(data);
      // console.log("---");

      loadUserjStorage(data.token);
      // examplePrivateAPI(data.token.access_token);
    },
    error: function(e){
      global.app.dialog.close();
      console.log(e);
    },
  });
}

export function doLogout(){
  var html='';

  html+='<div class="page">';
    html+='<div class="page-content login-screen-content">';
      html+='<div class="login-screen-title">'+t('Logout')+'</div>';
      html+='<form>';
        html+='<h3 style="text-align:center;">';
          html+='Sei sicuro di voler fare il logout?';
        html+='</h3>';
        html+='<div class="list">';
          html+='<ul>';
            html+='<li><a class="item-link list-button" id="submitLogout">Esci</a></li>';
          html+='</ul>';
        html+='</div>';

      html+='</form>';
    html+='</div>';
  html+='</div>';

  jQuery('#f7_content').html(html);
  jQuery('#footer').html("");

  jQuery("#submitLogout").click(function() {
    var s=storage.get();
    delete s.user;
    delete s.res;
    delete s.id_farm;
    delete s.all_farms;

    storage.save(s);
    location.hash='';

    tools.register();

    setTimeout(function(){
      location.reload(false);
    }, 10);
  });
}

// function examplePrivateAPI(token){
//   alert('Token:'+token);
//   jQuery.ajax({
//     type: 'GET',
//     data:{
//       access_token:token
//     },
//     url: global.base_call + 'get_users',
//     dataType: "json",
//     success: function(res){
//       alert("OK");
//       alert(JSON.stringify(res));
//     }
//   });
// }
