/*jshint esversion: 6 */

import storage from '../../../storage.js';
import global from '../../../global.js';
import notification from '../../../core/notification.js';
import t from '../../../translate.js';
import dbmng from '../../../ext/dbmng.js';
import model from '../model.js';
import * as partecipa from '../../../core/partecipa/partecipa_new.js';

import '../style.css';
import './olig.css';

// Se serve LEAFLET
//*********************************************************************
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
//*********************************************************************

global.app_version='1.0.0';

global.base_link='https://olig.aedit.it/'
global.base_path='https://olig.aedit.it/api/';
global.base_call=global.base_path+'userplus/';
global.base_call_aedita=global.base_path;

global.nomeApp='Olig+';
global.download_app_path='https://olig.aedit.it/app/Olig+.apk';
global.useSocialLogin=true;
global.settingsName='olig_settings';
// global.extBaseCall="http://agromap.arsia.toscana.it/agro18/api/mod_aedita_services/";

global.firebaseConfig = {
  apiKey: "AIzaSyDWcR3NoZPo6bYNKJGa0xmaQ2xb2qtTb0Y",
  authDomain: "olig-7561a.firebaseapp.com",
  projectId: "olig-7561a",
  storageBucket: "olig-7561a.appspot.com",
  messagingSenderId: "478671810621",
  appId: "1:478671810621:web:f1893f7ca61d8595bc3a54",
  measurementId: "G-1V48F3MKS4"
};

global.firebase=firebase.initializeApp(global.firebaseConfig);

global.messaging = null;

if (firebase.messaging.isSupported()){
  global.messaging = firebase.messaging();
}

if (notification) {
  global.notification=notification;
}

notification.default_notify();

//Hook obbligatorio per definire la home progetto
export function home_page(){
  partecipa.home_page();
}

export function tools(){
  partecipa.partecipa_tools();
}

//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  router.on({
    'model': function (param) {
      model.modello();
    },
    'notifica': function (param) {
      notification.notifica_home();
    }
  });

  router=partecipa.exe_routes(router);

  return router;
}


//Hook facoltativo per aggiungere delle sidebars al tema (non tutti i temi li gestiranno)
export function get_theme_param(theme_param){
  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': 'Modelli', 'icon_f7':'chart_bar_circle', 'href':'#model'},
    {'label': 'Dati', 'icon_f7':'map_pin_ellipse', 'href':'#view_data'},
    {'label': 'Notifica', 'icon_f7':'app_badge', 'href':'#notifica'},
  ];

  theme_param.sidebars=sidebars;
  theme_param.title='<img width="100" src="icons/olig_mobile.svg"/>';

  var s=storage.get();
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;
  }
  theme_param.ptr=false; // attiva/disattiva pull to refresh
  return theme_param;
}
